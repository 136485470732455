.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: transparent !important;
  border-radius: 10px;
  top: 0;
}

.value {
  text-align: center;
}

.custom-checkboxes {
  font-size: 16px;
  font-weight: 600;
}


/* New CSS */

/* Grid */
.TopicSection .grid {
  display: grid;
  grid-gap: 1em;
  margin: 0;
  padding: 0;
}
@media (min-width: 60em) {
  .TopicSection .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 90em) {
  .TopicSection .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Card Styles */
.TopicSection .card {
  background: #fff;
  border: 1px solid #e2ebf6;
  border-radius: 0.25em;
  cursor: pointer;
  display: flex;
  padding: 1em;
  position: relative;
  transition: all 0.2s;
}
.TopicSection .card:hover {
  border-color: #c4d1e1;
  box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.15);
  transform: translate(-4px, -4px);
}

.TopicSection .card__image {
  border-radius: 0.25em;
  height: 6em;
  min-width: 6em;
}

.TopicSection .card__content {
  flex: auto;
  padding: 0 1em;
}

.TopicSection .card h2 {
  font-weight: 700;
  margin: 0;
}

.TopicSection .card p {
  color: #546e7a;
  margin: 0;
}

/* Checkbox Styles */
.TopicSection .checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: #d9d9d9;
  border-radius: 50%;
  height: 2em;
  margin: 0;
  margin-left: auto;
  flex: none;
  outline: none;
  position: relative;
  transition: all 0.2s;
  width: 2em;
}
.TopicSection .checkbox:after {
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: "";
  display: block;
  height: 1em;
  left: 0.625em;
  position: absolute;
  top: 0.25em;
  transform: rotate(45deg);
  width: 0.5em;
}
.TopicSection .checkbox:focus {
  box-shadow: 0 0 0 2px rgba(100, 193, 117, 0.6);
  outline: none;
}
.TopicSection .checkbox:checked {
  background: #008753;
  border-color: #008753;
}

.TopicSection .checkbox-control__target {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}


.TopicSection {
  margin: 2em 2em 8em 2em;
}

.TopicSection .card__content .Topic_title {
  margin-bottom: 10px;
}

.TopicSection .card__content .Topic_title span {
  font-weight: 600;
  font-size: 18px;
}

.TopicSection .card__content .Topic_title ul {
  margin-left: 16px;
}

.TopicSection .card__content .Topic_title ul li {
  list-style: auto;
}

.TopicSection .card__content .Topic_title p {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-top: 5px;
}


.universityClass {
  border-color: #008753;
  background: #008753;
}

.custom-check-box .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 0% !important;
  position: absolute;
  left: 10px;
  top: 10px;
}

.custom-check-box input[type="checkbox"] {
  display: none;
}