#root {
  width: 100%;
}
body {
  font-family: "Montserrat", sans-serif !important;
  height: auto !important;
  font-weight: 400;
  font-size: 14px;
  color: #131313;
}
a:focus,
.btn:focus {
  outline: none;
  outline-offset: 0px;
}
ul {
  padding: 10px 0px;
  margin: 0px;
}
li {
  list-style-type: none;
}
#toTop {
  position: fixed;
  bottom: 20px;
  display: none;
  right: 20px;
  height: 50px;
  width: 50px;
  background: #008753;
  color: #fff;
  line-height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  z-index: 1111;
}
a:hover,
a:focus {
  text-decoration: none;
}
section {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 10;
}
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 600;
}
/*HEADER ANimation*/
.sticky-header header {
  top: 0;
  background: #171717;
  position: fixed;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInDown;
  -moz-animation-name: slideInDown;
  -ms-animation-name: slideInDown;
  animation-name: slideInDown;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.vh-center {
  display: table-cell;
  vertical-align: middle;
}
/*HEADER START*/
.custom-nav {
  /* padding: 0px 25px; */
  padding: 0 25px 10px !important;
  background: #fff;
}
.logo {
  float: left;
}
.logo a {
  display: block;
}
.main-nav ul li {
  display: inline-block;
}
.main-nav ul li a {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  padding: 13px 10px;
  color: #072b39;
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.main-nav ul li a:hover,
.main-nav ul li.active a {
  color: #008753;
  border-bottom: 2px solid #008753;
  padding-bottom: 3px;
}
.main-nav ul li.log-reg a {
  background: #008753;
  color: #fff;
  border-radius: 2px;
  padding: 5px 15px;
}
.main-nav ul li.log-reg a:hover {
  color: #fff;
}
.mar-left-nav {
  margin-left: 30px;
}
/*BANNER START*/
.banner-sec .item {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.banner-sec .item:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(7, 43, 57, 0.4);
}
.inner-banner-content {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
}
.inner-banner-content h1 {
  font-weight: bold;
  font-size: 40px;
  margin: 0px;
  letter-spacing: 2px;
}
.inner-banner-content p {
  margin: 30px 0;
  font-size: 16px;
  line-height: 30px;
}
.theme-btn {
  height: 45px;
  padding: 0 30px;
  background: #008753;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px;
}
.theme-btn:hover {
  color: #fff;
}
.theme-banner-btn {
  font-size: 16px;
  height: 50px;
}
.banner-sec .owl-controls {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
}
.banner-sec .owl-dots .owl-dot span {
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.banner-sec .owl-dots .owl-dot.active span {
  width: 50px;
}
/*WHY_CHOOSE_SEC*/
.why-choose-sec {
  padding: 4% 0;
}
.section-head {
  margin-bottom: 40px;
}
.section-head h6 {
  margin: 0px;
  color: #008753;
}
.section-head h1 {
  font-size: 32px;
  font-weight: bold;
  color: #072b39;
  margin: 20px 0;
}
.section-head p {
  color: #777;
  line-height: 24px;
}
.why-choos-list ul {
  margin: 0 -20px;
}
.why-choos-list ul li {
  width: 33.333%;
  float: left;
  padding: 20px 20px;
  border-right: 1px solid #eee;
}
.why-choos-list ul li .icon-chose {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 70px;
  border: 1px solid #008753;
  border-radius: 50%;
  font-size: 32px;
  color: #008753;
  position: relative;
  margin-bottom: 30px;
}
.why-choos-list ul li .icon-chose:before {
  position: absolute;
  left: 5px;
  top: 5px;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(0, 135, 83, 0.1);
  border-radius: 50%;
}
.why-choos-list ul li h5 {
  margin: 0px;
  color: #ddd;
}
.why-choos-list ul li h3 {
  font-size: 18px;
  font-weight: bold;
  color: #072b39;
  margin: 15px 0;
}
.why-choos-list ul li p {
  line-height: 30px;
  margin-bottom: 30px;
}
.chose-more {
  display: inline-block;
  height: 40px;
  padding: 0 30px;
  line-height: 38px;
  border: 1px solid #ddd;
  border-radius: 30px;
  color: #008753;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.chose-more:hover {
  color: #fff;
  background: #008753;
  border-color: #008753;
  padding: 0 45px;
}
.main-choose-outer {
  display: table;
  height: 300px;
}
.why-choos-list ul li:nth-child(1),
.why-choos-list ul li:nth-child(2),
.why-choos-list ul li:nth-child(3) {
  border-bottom: 1px solid #eee;
}
.why-choos-list ul li:nth-child(3),
.why-choos-list ul li:nth-child(6) {
  border-right: none;
}
/*EXAM_CATEGORY_START*/
.exam-category-sec {
  padding: 4% 0;
  background-color: #f1f7fc;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}
.exam-category-sec .section-head {
  text-align: center;
}
/*.exam-category-sec .section-head h1, .exam-category-sec .section-head p {
	color: #fff;
}*/
.exam-logos {
  height: 300px;
  background: #fff;
  border-radius: 10px;
  display: table;
  width: 100%;
  text-align: center;
}
.exam-logos img {
  width: 150px;
}
.exam-logos h4 {
  margin: 15px 0;
  font-weight: bold;
  color: #072b39;
  font-size: 18px;
}
.exam-category-sec .owl-controls {
  margin: 0px;
}
.exam-category-sec .owl-controls .owl-nav [class*="owl-"] {
  height: 40px;
  width: 40px;
  padding: 0px;
  margin: 0px;
  line-height: 40px;
  text-align: center;
  background: #008753;
  color: #fff;
  border-radius: 0px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
}
.exam-category-sec .owl-controls .owl-nav [class*="owl-"]:hover {
  background: #008753;
  color: #fff;
}
.owl-carousel .owl-controls .owl-nav .owl-prev {
  left: -60px;
}
.owl-carousel .owl-controls .owl-nav .owl-next {
  right: -60px;
}
/*ACHIVE_GOAL*/
.achive-goal-sec {
  padding: 4% 0;
}
.cust-tab .nav-tabs {
  border-bottom: none;
}
.cust-tab .nav-tabs > li {
  width: 16.666%;
  text-align: center;
  position: relative;
  height: 210px;
  padding: 0 10px;
}
.cust-tab .nav-tabs > li:after {
  position: absolute;
  left: 50%;
  content: "";
  bottom: 0px;
  height: 15px;
  width: 35px;
  content: "";
  margin-left: -17px;
  visibility: hidden;
  opacity: 0;
  background-image: url(../img/arrow-tab.png);
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.cust-tab .nav-tabs > li.active:after {
  visibility: visible;
  opacity: 1;
}
.cust-tab .nav-tabs > li > a span {
  color: #072b39;
}
.cust-tab .nav-tabs > li.active > a,
.cust-tab .nav-tabs > li > a {
  border: none;
  padding: 0px;
  margin: 0px;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.cust-tab .nav-tabs > li > a:hover {
  background: none;
}
.cust-tab .nav-tabs > li > a span {
  display: inline-block;
  height: 102px;
  width: 102px;
  text-align: center;
  line-height: 110px;
  position: relative;
  font-size: 54px;
  border-radius: 50%;
  background: #f1f7fc;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f1f7fc),
    to(#ffffff)
  );
  background: linear-gradient(to right, #f1f7fc 0%, #ffffff 100%);
  box-shadow: 0px 25px 30px 0px rgba(15, 111, 213, 0.15);
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.cust-tab .nav-tabs > li > a:hover span,
.cust-tab .nav-tabs > li.active > a span {
  color: #fff;
  background: #008753;
}
.cust-tab .nav-tabs > li > a span:before {
  content: "";
  width: 52px;
  height: 52px;
  background: rgba(15, 111, 213, 0.08);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-60%, -50%);
  transform: translate(-60%, -50%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 10;
}
.cust-tab .nav-tabs > li > a h4 {
  margin: 0;
  color: #001328;
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0 0px;
  line-height: 30px;
}
.cust-tab .tab-content {
  padding: 60px;
  border: 1px solid #ccd7e0;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(204, 215, 224, 0.3);
}
.cust-tab .tab-content > .tab-pane .media-left i {
  font-size: 80px;
  color: #008753;
  text-align: center;
}
.cust-tab .tab-content > .tab-pane .media-body {
  padding-left: 40px;
}
.cust-tab .tab-content > .tab-pane .media-body h4 {
  margin: 0;
  color: #072b39;
  font-size: 24px;
  font-weight: 800;
  margin: 0 0 20px;
}
.cust-tab .tab-content > .tab-pane .media-body p {
  line-height: 30px;
  color: #798593;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0 30px;
}
.redmor {
  display: inline-block;
  color: #0057b3;
  text-decoration: underline;
  font-weight: bold;
  letter-spacing: 0.03em;
  font-size: 16px;
}
/*TESTIMONIAL_START*/
.test-sec {
  padding: 4% 0;
}
.test-sec:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  /*background-color: rgb(7,28,54,0.9);*/
  background-color: #008753;
  opacity: 0.8;
}
.test-sec .section-head h1,
.test-sec .section-head p {
  color: #fff;
}
.testi-inner {
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}
.user-pros {
  /* height: 100px;
  width: 100px; */
  height: 48px !important;
  width: 48px !important;
  border-radius: 50%;
  background: #ddd;
  margin: auto;
}
.user-pros img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  padding: 1px;
}
.testi-inner h4 {
  font-weight: bold;
  margin: 20px 0 10px;
}
.testi-inner h6 {
  color: #008753;
  font-size: 12px;
  margin-bottom: 30px;
}
.testi-inner p {
  font-size: 16px;
  line-height: 30px;
}
.test-sec .owl-dots .owl-dot span {
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.test-sec .owl-dots .owl-dot.active span {
  width: 50px;
}

/*NEW_SEC_START*/
.news-sec {
  padding: 4% 0;
}
.news-sec .section-head {
  text-align: center;
}
.top-blog {
  text-align: center;
  padding: 40px;
  border-radius: 7px;
  background: #f1f7fc;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.top-blog:before {
  height: 0%;
  width: 100%;
  content: "";
  background: #072b39;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.news-sec .col-sm-4:hover .top-blog:before {
  height: 100%;
}
.top-blog h4 {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.top-blog h4 span {
  display: inline-block;
  margin: 0 10px;
}
.top-blog h1 {
  margin: 20px 0;
  font-size: 19px;
  color: #001328;
  font-weight: 700;
  line-height: 26px;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.news-sec .col-sm-4:hover .top-blog h4,
.news-sec .col-sm-4:hover .top-blog h1,
.news-sec .col-sm-4:hover .top-blog .redmor {
  color: #fff;
}
.bottom-blog {
  height: 300px;
  border-radius: 7px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.bottom-blog .play-blog-caption {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transform: scale(2);
  -o-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.bottom-blog:hover .play-blog-caption {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
}
.bottom-blog .play-blog-caption a {
  height: 50px;
  background: #fff;
  color: #333;
  width: 50px;
  text-align: center;
  line-height: 55px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 16px;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.bottom-blog .play-blog-caption a:hover {
  color: #008753;
}
/*FOOTER*/
footer {
  padding: 0;
  background: #001328;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/gray-bg.png);
}
footer h3 {
  margin: 0 0 20px;
  font-weight: bold;
  color: #fff;
}
.top-footer {
  padding: 5% 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
}
.footer-logo {
  margin: 40px 0 0;
}
.footer-logo img {
  width: 90%;
}
.footer-nav ul li {
  display: block;
  margin: 10px 0;
  position: relative;
}
.footer-nav ul li:after {
  position: absolute;
  left: -5px;
  top: 3px;
  color: #008753;
  font-size: 14px;
  content: "\e915";
  font-family: "ots";
  visibility: hidden;
  opacity: 0;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.footer-nav ul li:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.footer-nav ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.footer-nav ul li:hover a {
  padding-left: 20px;
}
.social-nav {
  padding: 0;
}
.foot-btn {
  display: inline-block;
  height: 48px;
  line-height: 50px;
  text-transform: uppercase;
  padding: 0 30px;
  font-weight: 600;
  color: #fff;
  border-radius: 50px;
  background: #008753;
}
.foot-btn:hover {
  color: #fff;
}
.social-nav ul {
}
.social-nav ul li {
  display: inline-block;
}
.social-nav ul li a {
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-size: 18px;
  padding: 0 10px;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.social-nav ul li a:hover {
  color: #008753;
}
.bot-footer {
  padding: 20px 0;
  text-align: center;
}
.bot-footer p {
  margin: 0px;
  color: #fff;
  letter-spacing: 1px;
}
.bot-footer p a {
  color: #fff;
  text-decoration: underline;
}
.social-nav .form-control {
  height: 50px;
  border-radius: 0px;
  background: #0f2237;
  color: #fff;
  border: none;
  margin-bottom: 20px;
  border-radius: 50px;
  padding: 0 30px;
}
/*ABOUT US*/
.page-banner-sec {
  padding: 140px 0 70px;
  position: relative;
  color: #fff;
  z-index: 1;
}
.page-banner-sec h1 {
  font-weight: bold;
  /*margin-top: 20px;*/
  margin-top: 10px !important;
  font-size: 42px;
}
.page-banner-sec:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: #008753;
  opacity: 0.7;
  z-index: -1;
}
.paginations-cust li {
  padding: 0 10px;
  display: inline-block;
  position: relative;
}
.paginations-cust li:after {
  position: absolute;
  right: -5px;
  top: 50%;
  content: "";
  background: #fff;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin-top: -3px;
}
.paginations-cust li:last-child:after {
  display: none;
}
.paginations-cust li a {
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
.cms-content-sec {
  padding: 4% 0;
}
.cms-content-sec h1 {
  font-size: 28px;
  font-weight: bold;
  color: #072b39;
  margin: 20px 0;
}
.cms-content-sec p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
}
.bulet-points {
  padding-left: 30px;
  border-left: 5px solid #eee;
}
.bulet-points li {
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  font-size: 16px;
}
.bulet-points li:after {
  position: absolute;
  left: 0;
  top: 10px;
  content: "";
  height: 10px;
  width: 10px;
  background: #008753;
  border-radius: 50%;
}
/*NEWS-PAGE*/
.news-page-sec {
  padding: 4% 0;
}
.news-page-sec p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
}
.news-page h1 {
  font-size: 19px;
}
.news-page-sec .top-blog {
  margin-bottom: 0px;
}
.news-page-sec .bottom-blog {
  margin-bottom: 40px;
}

/*LOG_REGISTRATION*/
.registration-page {
  background: #f5f5f5;
}
.left-regi {
  position: relative;
  float: left;
  width: 35%;
  display: table;
  height: 100vh;
  padding: 0 50px;
  z-index: 1;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center center;
}
.left-regi:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  /*background: #008753;
  opacity: 0.7;*/
}
.left-regi h1 {
  margin: 20px 0;
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
}
.left-regi p {
  line-height: 26px;
  font-size: 14px;
  margin-bottom: 30px;
}
.regi-bg-opacity {
  background: #008753;
  opacity: 0.5;
  border-radius: 5px;
  margin: 5px;
}
.left-regi .btn {
  display: inline-block;
  height: 50px;
  padding: 0 30px;
  line-height: 38px;
  background: #008753;
  border: 1px solid #008753;
  border-radius: 30px;
  color: #fff;
}
.right-regi-step {
  width: 65%;
  float: left;
  display: table;
  height: 100vh;
  overflow: auto;
  padding: 0 100px;
  position: relative;
}
.right-regi-step h1 {
  margin: 20px 0;
  font-size: 30px;
  color: #071c36;
  font-weight: 700;
  letter-spacing: 1px;
}
.right-regi-step h1 span {
  color: #008753;
}
.inner-que .log-btn-main {
  margin-top: 20px;
}
.dont-have-ac a {
  color: #333;
  font-weight: bold;
  text-decoration: underline;
}
.inner-que .log-btn-main .btn {
  display: inline-block;
  height: 50px;
  padding: 0 30px;
  line-height: 38px;
  background: #008753;
  border: 1px solid #008753;
  border-radius: 30px;
  color: #fff;
}
.reg-logo {
  margin: auto;
  display: inline-block;
}
.reg-logo a {
  display: inline-block;
}
.reg-logo a img {
  width: 150px;
}
.inner-que {
  padding: 40px 0 20px;
  text-align: left;
}
.inner-que .form-control {
  height: 45px;
  background: rgba(7, 28, 54, 0.05);
  border-radius: 0px;
  box-shadow: none;
  border: none;
}
.inner-que textarea.form-control {
  height: 80px;
}
.inner-que .checkbox a {
  font-weight: bold;
  color: #008753;
}

.top-header {
  background: #fff;
  border-bottom: 5px solid #008753;
}
/* .logo {
  float: left;
  text-align: center;
  width: 280px;
  background: #fff;
  padding: 12px 10px;
  border-right: 1px solid #008753;
} */
.logo {
  float: none;
  text-align: center;
  width: 150px !important;
  background: #fff;
  /* padding: 10px; */
  /* border-right: 1px solid #008753; */
  position: absolute;
  left: 20px;
  border-radius: 0 0 20px 20px;
}

.cust-tab .nav-tabs {
  padding-bottom: 0px;
}

.dash-logo {
  font-size: 18px;
  width: 250px !important;
  /* padding: 24px; */
  border-right: 1px solid #008753;
  position: absolute;
  left: 30px;
  text-emphasis: none;
}

.dash-logo img {
  width: 145px ;
}
.left-user-detail {
  padding: 6px 0;
  width: 300px;
  float: left;
  /* margin-left: 30px; */
  margin-left: 300px;
}
/* .user-pros {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid #eee;
}
.user-pros img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
} */
.left-user-detail .media h4 {
  font-size: 16px;
  color: #333;
  margin: 0px;
  font-weight: bold;
}
.left-user-detail .media p {
  margin: 0px;
}
.left-user-detail .media-body,
.left-user-detail .media-left,
.left-user-detail .media-right {
  vertical-align: middle;
}
.right-nav {
  float: right;
}
.right-nav ul li {
  display: inline-block;
  position: relative;
}
.right-nav ul li:before {
  position: absolute;
  left: -2px;
  top: 20%;
  width: 1px;
  height: 60%;
  content: "";
  background: #eee;
}
.right-nav ul li:first-child:before {
  display: none;
}
.right-nav ul li a {
  display: block;
  font-size: 14px;
  color: #333;
  padding: 22px 20px;
}
.main-authoer-sec {
  min-height: 100vh;
  position: relative;
}
.bot-header {
  background: #26c6da;
  border-bottom: 1px solid #071c36;
  border-top: 1px solid #071c36;
}
.bot-header ul {
  text-align: center;
}
.bot-header ul li {
  display: inline-block;
  position: relative;
}
.bot-header ul li:before {
  position: absolute;
  right: -2px;
  top: 0;
  width: 1px;
  height: 100%;
  content: "";
  background: #071c36;
}
.bot-header ul li:last-child:before {
  display: none;
}
.bot-header ul li a {
  display: block;
  color: #fff;
  padding: 7px 15px;
}
.main-view-content {
}
.all-ourt-style {
  padding: 15px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 15px !important;
}
.w-80-cust {
  width: 90%;
  margin: 0 auto;
}
.all-heads {
  padding-bottom: 0px;
  padding-left: 50px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.editable-forms-users {
  padding: 20px;
}
.editable-forms-users label {
  color: #131313;
}
.foot-btns {
  margin-top: 40px;
}
.foot-btns .btn {
  display: inline-block;
  height: 50px;
  padding: 0 30px;
  line-height: 38px;
  background: #008753;
  border: 1px solid #008753;
  border-radius: 30px;
  color: #fff;
}
.all-heads h3 {
  margin: 0px 0px 10px;
  font-size: 16px;
  color: #333;
}
.all-heads h6 {
  margin: 0px;
}
.custom-radios ul {
  margin: 0 -5px;
}
.custom-radios ul li {
  float: left;
  width: 33.333%;
  padding: 0 5px;
  margin-bottom: 10px;
}
.custom-radios label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #eee;
  font-weight: 500;
  margin-bottom: 0px;
}
/*---Author Dash Sec---*/
.custom-nav-dash {
  padding: 0px 0px !important;
  background: #fff;
}
.custom-nav-dash ul {
  padding: 0px 0px !important;
  background: #fff;
  margin-bottom: 0px;
}
.auth-dash-sec {
  padding: 72px 0 0;
  position: relative;
}
.auth-dash-sec .left-navigations {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 280px;
  background: #008753;
  padding: 72px 0 0;
  overflow: auto;
}
.left-navigations ul {
}
.left-navigations ul li {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
  z-index: 1;
}
.left-navigations ul li:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  content: "";
  height: 100%;
  background: #14df7d;
  z-index: -1;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.left-navigations ul li:hover:before,
.left-navigations ul li.active:before {
  width: 100%;
  background: #14df7d;
}
.left-navigations ul li a {
  display: block;
  color: #fff;
  font-weight: normal;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
  padding: 16px 20px;
}
.left-navigations ul li a i {
  margin-right: 15px;
  font-size: 20px;
}
.left-navigations ul li a:hover {
  text-decoration: none;
}
.auth-dash-sec .right-auth-landing {
  padding: 20px 20px 20px 300px;
}
.work-info {
  position: relative;
}
.work-info:before {
  border-color: #ffffff rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 0 42px 42px 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4), 0 0 10px rgba(0, 0, 0, 0.3);
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.login-page,
.register-page {
  background: #f5f5f5 !important;
}

.react-datepicker-wrapper {
  display: inline !important;
}

sup {
  color: #ff0000 !important;
  top: -0.5em !important;
  font-weight: bold !important;
}

span.sup {
  color: #ff0000 !important;
  top: -0.5em !important;
  font-weight: bold !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: rgba(7, 28, 54, 0.05) !important;
  height: 45px !important;
  border-radius: 0px !important;
  width: 100% !important;
}
#mobile_number .flag-dropdown {
  display: none !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

/*SUBSCRIPTION PAGE*/
.subscription-page {
  padding: 5% 0;
}
.end-plan {
  text-align: center;
  padding: 60px;
  border: 1px solid #ccd7e0;
  border-radius: 7px;
  background: #fff;
  margin-bottom: 40px;
  box-shadow: 0px 10px 30px 0px rgba(204, 215, 224, 0.3);
}
.end-plan h1 {
  margin: 0 0 20px;
  font-weight: bold;
}
.custom-radios-plan .option {
  background: #fff;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  padding: 35px 14px 25px;
  margin: 0px;
  float: left;
  width: 30%;
  position: relative;
  border: 1px solid #ccd7e0;
  transition: all 0.3s ease;
  text-align: center;
  box-shadow: 0px 10px 30px 0px rgba(204, 215, 224, 0.3);
  min-height: 309px;
  margin: 0px 10px 24px;
}
.custom-radios-plan .option:nth-child(even) {
  background: rgba(0, 135, 83, 0.1);
}
.custom-radios-plan .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
}
.custom-radios-plan .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
.custom-radios-plan input[type="radio"] {
  display: none;
}

.checked {
  border-color: #008753 !important;
  background: #008753 !important;
}

.checked .dot {
  background: #fff !important;
}

.checked .dot::before {
  opacity: 1 !important;
  transform: scale(1) !important; 
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3,
#option-4:checked:checked ~ .option-4,
#option-5:checked:checked ~ .option-5,
#option-6:checked:checked ~ .option-6,
#option-7:checked:checked ~ .option-7,
#option-8:checked:checked ~ .option-8,
#option-9:checked:checked ~ .option-9,
#option-10:checked:checked ~ .option-10,
#option-11:checked:checked ~ .option-11,
#option-12:checked:checked ~ .option-12,
#option-13:checked:checked ~ .option-13,
#option-14:checked:checked ~ .option-14,
#option-15:checked:checked ~ .option-15,
#option-16:checked:checked ~ .option-16,
#option-17:checked:checked ~ .option-17,
#option-18:checked:checked ~ .option-18,
#option-19:checked:checked ~ .option-19,
#option-20:checked:checked ~ .option-20,
#option-21:checked:checked ~ .option-21,
#option-22:checked:checked ~ .option-22,
#option-23:checked:checked ~ .option-23,
#option-24:checked:checked ~ .option-24,
#option-25:checked:checked ~ .option-25,
#option-26:checked:checked ~ .option-26,
#option-27:checked:checked ~ .option-27,
#option-28:checked:checked ~ .option-28,
#option-29:checked:checked ~ .option-29,
#option-30:checked:checked ~ .option-30,
#option-31:checked:checked ~ .option-31,
#option-32:checked:checked ~ .option-32,
#option-33:checked:checked ~ .option-33,
#option-34:checked:checked ~ .option-34,
#option-35:checked:checked ~ .option-35,
#option-36:checked:checked ~ .option-36,
#option-37:checked:checked ~ .option-37,
#option-38:checked:checked ~ .option-38,
#option-39:checked:checked ~ .option-39,
#option-40:checked:checked ~ .option-40,
#option-41:checked:checked ~ .option-41,
#option-42:checked:checked ~ .option-42,
#option-43:checked:checked ~ .option-43,
#option-44:checked:checked ~ .option-44,
#option-45:checked:checked ~ .option-45,
#option-46:checked:checked ~ .option-46,
#option-47:checked:checked ~ .option-47,
#option-48:checked:checked ~ .option-48,
#option-49:checked:checked ~ .option-49,
#option-50:checked:checked ~ .option-50 {
  border-color: #008753;
  background: #008753;
}

#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot,
#option-3:checked:checked ~ .option-3 .dot,
#option-4:checked:checked ~ .option-4 .dot,
#option-5:checked:checked ~ .option-5 .dot,
#option-6:checked:checked ~ .option-6 .dot,
#option-7:checked:checked ~ .option-7 .dot,
#option-8:checked:checked ~ .option-8 .dot,
#option-9:checked:checked ~ .option-9 .dot,
#option-10:checked:checked ~ .option-10 .dot,
#option-11:checked:checked ~ .option-11 .dot,
#option-12:checked:checked ~ .option-12 .dot,
#option-13:checked:checked ~ .option-13 .dot,
#option-14:checked:checked ~ .option-14 .dot,
#option-15:checked:checked ~ .option-15 .dot,
#option-16:checked:checked ~ .option-16 .dot,
#option-17:checked:checked ~ .option-17 .dot,
#option-18:checked:checked ~ .option-18 .dot,
#option-19:checked:checked ~ .option-19 .dot,
#option-20:checked:checked ~ .option-20 .dot,
#option-21:checked:checked ~ .option-21 .dot,
#option-22:checked:checked ~ .option-22 .dot,
#option-23:checked:checked ~ .option-23 .dot,
#option-24:checked:checked ~ .option-24 .dot,
#option-25:checked:checked ~ .option-25 .dot,
#option-26:checked:checked ~ .option-26 .dot,
#option-27:checked:checked ~ .option-27 .dot,
#option-28:checked:checked ~ .option-28 .dot,
#option-29:checked:checked ~ .option-29 .dot,
#option-30:checked:checked ~ .option-30 .dot,
#option-31:checked:checked ~ .option-31 .dot,
#option-32:checked:checked ~ .option-32 .dot,
#option-33:checked:checked ~ .option-33 .dot,
#option-34:checked:checked ~ .option-34 .dot,
#option-35:checked:checked ~ .option-35 .dot,
#option-36:checked:checked ~ .option-36 .dot,
#option-37:checked:checked ~ .option-37 .dot,
#option-38:checked:checked ~ .option-38 .dot,
#option-39:checked:checked ~ .option-39 .dot,
#option-40:checked:checked ~ .option-40 .dot,
#option-41:checked:checked ~ .option-41 .dot,
#option-42:checked:checked ~ .option-42 .dot,
#option-43:checked:checked ~ .option-43 .dot,
#option-44:checked:checked ~ .option-44 .dot,
#option-45:checked:checked ~ .option-45 .dot,
#option-46:checked:checked ~ .option-46 .dot,
#option-47:checked:checked ~ .option-47 .dot,
#option-48:checked:checked ~ .option-48 .dot,
#option-49:checked:checked ~ .option-49 .dot,
#option-50:checked:checked ~ .option-50 .dot {
  background: #fff;
}

#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before,
#option-3:checked:checked ~ .option-3 .dot::before,
#option-4:checked:checked ~ .option-4 .dot::before,
#option-5:checked:checked ~ .option-5 .dot::before,
#option-6:checked:checked ~ .option-6 .dot::before,
#option-7:checked:checked ~ .option-7 .dot::before,
#option-8:checked:checked ~ .option-8 .dot::before,
#option-9:checked:checked ~ .option-9 .dot::before,
#option-10:checked:checked ~ .option-10 .dot::before,
#option-11:checked:checked ~ .option-11 .dot::before,
#option-12:checked:checked ~ .option-12 .dot::before,
#option-13:checked:checked ~ .option-13 .dot::before,
#option-14:checked:checked ~ .option-14 .dot::before,
#option-15:checked:checked ~ .option-15 .dot::before,
#option-16:checked:checked ~ .option-16 .dot::before,
#option-17:checked:checked ~ .option-17 .dot::before,
#option-18:checked:checked ~ .option-18 .dot::before,
#option-19:checked:checked ~ .option-19 .dot::before,
#option-20:checked:checked ~ .option-20 .dot::before,
#option-21:checked:checked ~ .option-21 .dot::before,
#option-22:checked:checked ~ .option-22 .dot::before,
#option-23:checked:checked ~ .option-23 .dot::before,
#option-24:checked:checked ~ .option-24 .dot::before,
#option-25:checked:checked ~ .option-25 .dot::before,
#option-26:checked:checked ~ .option-26 .dot::before,
#option-27:checked:checked ~ .option-27 .dot::before,
#option-28:checked:checked ~ .option-28 .dot::before,
#option-29:checked:checked ~ .option-29 .dot::before,
#option-30:checked:checked ~ .option-30 .dot::before,
#option-31:checked:checked ~ .option-31 .dot::before,
#option-32:checked:checked ~ .option-32 .dot::before,
#option-33:checked:checked ~ .option-33 .dot::before,
#option-34:checked:checked ~ .option-34 .dot::before,
#option-35:checked:checked ~ .option-35 .dot::before,
#option-36:checked:checked ~ .option-36 .dot::before,
#option-37:checked:checked ~ .option-37 .dot::before,
#option-38:checked:checked ~ .option-38 .dot::before,
#option-39:checked:checked ~ .option-39 .dot::before,
#option-40:checked:checked ~ .option-40 .dot::before,
#option-41:checked:checked ~ .option-41 .dot::before,
#option-42:checked:checked ~ .option-42 .dot::before,
#option-43:checked:checked ~ .option-43 .dot::before,
#option-44:checked:checked ~ .option-44 .dot::before,
#option-45:checked:checked ~ .option-45 .dot::before,
#option-46:checked:checked ~ .option-46 .dot::before,
#option-47:checked:checked ~ .option-47 .dot::before,
#option-48:checked:checked ~ .option-48 .dot::before,
#option-49:checked:checked ~ .option-49 .dot::before,
#option-50:checked:checked ~ .option-50 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.custom-radios-plan .option span {
  font-size: 30px;
  color: #000;
  margin-bottom: 20px;
  display: block;
}
.custom-radios-plan .option span b {
  display: block;
  font-size: 20px;
  font-weight: 300;
  min-height: 100px;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span,
#option-3:checked:checked ~ .option-3 span,
#option-4:checked:checked ~ .option-4 span,
#option-5:checked:checked ~ .option-5 span,
#option-6:checked:checked ~ .option-6 span,
#option-7:checked:checked ~ .option-7 span,
#option-8:checked:checked ~ .option-8 span,
#option-9:checked:checked ~ .option-9 span,
#option-10:checked:checked ~ .option-10 span,
#option-11:checked:checked ~ .option-11 span,
#option-12:checked:checked ~ .option-12 span {
  color: #fff;
}
.custom-radios-plan label {
}

.custom-radios-plan .wrapper .chose-more {
  color: #fff;
  background: #008753;
  font-weight: 100;
  border-color: #008753;
  font-size: 20px;
}
.next-prev-steps {
  margin-top: 40px;
}
.extype {
  position: relative;
  margin: 0 auto 20px;
  width: 150px;
  height: 150px;
  text-align: center;
}
.extype img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}
.custom-radios-plan.examtype-spaner .option span {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
}

.custom-radios-plan.course-spaner .option span {
  font-size: 18px;
  height: 50px;
}

/*PAYMENT - 13 may*/
.payment-cart {
  padding: 30px;
  border: 1px solid #ccd7e0;
  border-radius: 15px;
  background: #fff;
  margin-bottom: 0px;
  box-shadow: 0px 10px 30px 0px rgba(204, 215, 224, 0.3);
}
.payment-cart h1 {
  margin: 0 0 20px;
  font-weight: bold;
  font-size: 18px;
}

.payment-cart .form-control {
  height: 50px;
  border: none;
  background: #eee;
  box-shadow: none;
  border: none;
  border-radius: 0px;
}
.pay-logo img {
  width: 200px;
}
.news-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 30px;
}
.news-image {
  margin-bottom: 20px;
}
.news-image img {
  border-radius: 20px;
  width: 100%;
}

.custom-check-box .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 0% !important;
  position: absolute;
  left: 10px;
  top: 10px;
}

.custom-check-box input[type="checkbox"] {
  display: none;
}

/*exam-set*/
.exam-set .checkbox {
  margin: 3px 0;
  font-weight: bold;
}

.exam-set h2 {
  font-size: 18px;
  color: #333;
  margin: 0px;
  padding: 0 0 10px 0;
}
.exam-set h2 a {
  display: inline-block;
  margin-right: 10px;
  color: #008753;
}
.answer-selection {
  padding: 20px;
}
.cust-redio {
  margin: 10px 0;
}
.cust-redio form {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.cust-redio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  font-size: 14px;
  margin-right: 10px;
}
.cust-redio label input {
  position: absolute;
  left: -9999px;
}
.cust-redio label input:checked + span {
  background-color: rgba(119, 195, 218, 0.2);
}
.cust-redio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #008753;
}
.cust-redio label span {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}
.cust-redio label span:hover {
  background-color: rgba(119, 195, 218, 0.2);
}
.cust-redio label span:before {
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0.375em;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #3f4257;
}
.next-prev-cust .btn {
  height: 40px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.next-prev-cust .view-ans-btn {
  background: #008753;
  color: #fff;
}
.next-prev-cust .nxt-prv {
  background: none;
  color: #333;
  margin-left: 20px;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.next-prev-cust .nxt-prv:hover {
  color: #008753;
}
.user-exam {
  padding: 20px;
  background: #fff;
}
.user-pros-exam {
  height: 40px;
  width: 40px;
  margin: auto;
  border: 5px solid #ddd;
  border-radius: 50%;
}
.user-pros-exam img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.user-exam h4 {
  text-align: center;
  margin: 10px 0;
  color: #333;
  font-size: 16px;
}
.bot-examers {
  /*padding: 0 20px;*/
  padding: 0 10px;
}
.bot-examers h2 {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.bot-examers .subject-tab ul li a {
  padding: 8px 10px;
  font-size: 15px;
}
.bot-examers .subject-tab ul li:before {
  display: none;
}
.subject-tab {
  color: #fff;
}

.boxes {
  margin: auto;
}

/*Checkboxes styles*/
.boxes input[type="checkbox"] {
  display: none;
}

.boxes input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px;
  color: #ddd;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-weight: 500;
  color: #333;
}

.boxes input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}

.boxes input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #008753;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

.boxes input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.questions-opt h5 {
  margin: 0px 0 20px;
  padding: 0;
  color: #333;
  font-weight: bold;
}
.questions-opt {
  padding: 20px;
  background: #fff;
}
.number-lister {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}
.number-lister a {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #fff;
  color: #333;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
}
.legend-label p {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0px;
}
.legend-label p span {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid #ddd;
  vertical-align: middle;
  border-radius: 4px;
}

.sub {
  color: #ff0000;
  font-size: 12px;
  padding-left: 3px;
}

.owl-carousel .owl-item img {
  display: inline-block !important;
  width: 100% !important;
}

/*28-may*/
.sel-exam-quest h4 {
  margin: 0 0 10px;
  color: #333;
  font-weight: bold;
}

.quet-lister {
  margin-top: 20px;
}
.quet-lister a {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #fff;
  color: #333;
  border: 3px solid #ddd;
  line-height: 34px;
  text-align: center;
  font-weight: bold;
  transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -webkit-transition: all 300ms linear 0s;
}
.quet-lister a.active,
.quet-lister a:hover {
  background: #008753;
  color: #fff;
  border-color: #008753;
}
.lister-opt-ans h3 {
  margin: 10px 0;
  font-weight: normal;
  font-size: 18px;
  padding: 10px 20px;
  background: rgba(0, 135, 83, 0.2);
  border-radius: 30px;
}
.lister-opt-ans h3 span {
  font-weight: bold;
  color: #000;
  font-size: 22px;
  display: inline-block;
  margin-right: 10px;
}
.over-all-results-graph {
}
.graph-box h3 {
  font-size: 20px;
  margin: 0 0 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.graph-box h3 span {
  color: #008753;
  font-size: 16px;
  font-weight: 500;
}
.over-al-head {
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
  font-size: 20px;
  color: #000;
  padding-bottom: 15px;
  text-align: center;
}
.over-al-head span {
  font-weight: bold;
  color: #008753;
}

.result-boxers h1 {
  font-size: 20px;
  margin: 0 0 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.result-boxers h1 span {
  color: #008753;
  font-weight: 500;
}

.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.09em solid #000000;
  width: 0.82em;
  height: 0.82em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wrapper {
  /* width: 1200px; */
  margin: 0 auto;
}

.c100 {
  position: relative;
  font-size: 160px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 15px auto;
  background-color: #dfe8ed;
}
.c100 *,
.c100 *:before,
.c100 *:after {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #3c4761;
  display: block;
  text-align: center;
  white-space: nowrap;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.c100:after {
  position: absolute;
  top: 0.09em;
  left: 0.09em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #ffffff;
  width: 0.82em;
  height: 0.82em;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.p1 .bar {
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.c100.p6 .bar {
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.c100.p11 .bar {
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  -moz-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.c100.p14 .bar {
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.c100.p16 .bar {
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.c100.p21 .bar {
  -moz-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.c100.p22 .bar {
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c100.p26 .bar {
  -moz-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.c100.p27 .bar {
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.c100.p31 .bar {
  -moz-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.c100.p32 .bar {
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.c100.p36 .bar {
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  -moz-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.c100.p38 .bar {
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.c100.p41 .bar {
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  -moz-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.c100.p43 .bar {
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.c100.p46 .bar {
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  -moz-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.c100.p48 .bar {
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100.p51 .bar {
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  -moz-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.c100.p53 .bar {
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.c100.p56 .bar {
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  -moz-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.c100.p58 .bar {
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.c100.p61 .bar {
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  -moz-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.c100.p63 .bar {
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.c100.p66 .bar {
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  -moz-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.c100.p68 .bar {
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.c100.p71 .bar {
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  -moz-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.c100.p75 .bar {
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c100.p76 .bar {
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  -moz-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.c100.p80 .bar {
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.c100.p81 .bar {
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  -moz-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.c100.p85 .bar {
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.c100.p86 .bar {
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  -moz-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.c100.p90 .bar {
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.c100.p91 .bar {
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  -moz-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.c100.p95 .bar {
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.c100.p96 .bar {
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  -moz-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.c100.p100 .bar {
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.c100:hover {
  cursor: default;
}
.c100:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #3c4761;
}
.c100:hover:after {
  top: 0.07em;
  left: 0.07em;
  width: 0.86em;
  height: 0.86em;
}
.c100.blue .bar,
.c100.blue .fill {
  border-color: #30bae7 !important;
}
.c100.blue:hover > span {
  color: #3c4761;
}
.c100.pink .bar,
.c100.pink .fill {
  border-color: #d74680 !important;
}
.c100.pink:hover > span {
  color: #3c4761;
}
.c100.green .bar,
.c100.green .fill {
  border-color: #15c7a8 !important;
}
.c100.green:hover > span {
  color: #3c4761;
}
.c100.orange .bar,
.c100.orange .fill {
  border-color: #eb7d4b !important;
}
.c100.orange:hover > span {
  color: #3c4761;
}

.main-sidebar {
  height: 100vh;
  overflow: auto;
}

sup {
  color: #ff0000 !important;
  top: -0.5em !important;
  font-weight: bold !important;
}

.sup {
  color: #ff0000 !important;
  top: -0.5em !important;
  font-weight: bold !important;
}
.invalid-feedback {
  color: #ff0000 !important;
}

.newsletter .btn {
  display: inline-block;
  height: 50px;
  padding: 0 30px;
  line-height: 38px;
  background: #008753;
  border: 1px solid #008753;
  border-radius: 30px;
  color: #fff;
  margin-top: 10px;
}
.newsletter .btn:hover {
  text-decoration: underline;
  color: #fff;
}

.social-nav .form-control {
  margin-bottom: 2px !important;
}

.inner-que .log-btn-main .btn:hover {
  text-decoration: underline;
  color: #fff;
}
.text-danger {
  color: #ff0000;
}

.owl-carousel .owl-item img {
  display: inline-block !important;
  width: 100% !important;
}

.question-title img,  .answer-selection img{
  /* height: 150px !important; */
}
.log-logo {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  margin: auto;
}
.log-logo img {
  width: 120px;
}
.next-prev-cust .view-ans-btn {
  height: 35px !important;
  padding: 0px 15px;
  line-height: 35px;
}
.m-auto {
  margin: auto;
}
.font-16px {
  font-size: 16px !important;
}
.c100 > span.chart-headsVis {
  margin-top: 60px;
  padding: 0 20px !important;
  width: 100%;
  box-sizing: border-box;
}