.bot-examers .subject-tab ul li button {
	padding: 8px 10px;
	font-size: 15px;
	color: #000;
}

.number-lister {
	text-align: left;
}

.number-lister a {
	text-decoration: none;
	border-radius: 20%;
	margin-top: 4px !important;
}
.number-lister a:hover,
.number-lister a:active,
.number-lister a:focus {
	background: #eacf0a !important;
	color: #333;
}

.isActiveQuestion {
	color: #fff !important;
	background: #eacf0a !important;
}

.markForReviewQuestion {
	color: #fff !important;
	background: #ff0000 !important;
}

.markForAnsweredQuestion {
	color: #fff !important;
	background: #59AC33 !important;
}

.correct-answer {
	padding: 5px 0;
}

.timer {
	padding: 5px;
}
.legend-label div {
	display: inline-block;
	font-size: 20px;
}

.answer-selection {
	padding: 0px;
}

.view-ans-btn {
	background: #008753;
	color: #fff !important;
}

.submit-ans-btn {
	background: #246ce2;
	color: #fff !important;
}

.question-title {
	/* width: 30em; */
	/* max-width: 100%; */
}
.question-title p {
	/* display: inline-block; */
	max-width: 100%;
}
/* .question-title p:nth-child(1) {
	float: left;
} */
/* .question-title p:nth-child(2) { float:right; } */
