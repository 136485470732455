.navbar-nav {
  margin: 3px;
}
.navbar-nav>li>a {
  padding-top: 0px;
  padding-bottom: 0px;
}

/*
* Loader css
*/

/* .loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  animation: 1ds-dual-ring 1.2s linear infinite;
}

@keyframes ds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust the height as needed */
}

.spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.react-tel-input .form-control {
  background: rgb(233 234 236) !important;
  height: 32px !important;
  border: 0px !important;
}

.react-tel-input .flag-dropdown {
  background-color: #e9eaec;
  border: 0px;
}

.react-tel-input .form-control:focus {
  box-shadow: none;
}