.subject-list {
    /* padding: 0px 0 0 15px !important; */
}

.subject-list>li {
    /* list-style-type: circle !important; */
}

.subject-list>li label {
    font-weight: 500;
}