#img-preview {
  width: 200px;
  padding: 1px;
  border: 1px dotted #cdb;
}

.form-check-input {
  margin: 4px 10px 0 !important;
}

.mt-30 {
  margin-top: 30px;
}