#img-preview {
  width: 200px;
  padding: 1px;
  border: 1px dotted #cdb;
}

.form-check-input {
  margin: 4px 10px 0 !important;
}
.wrs_image {
  height: auto;
  width: auto;
}

.queImage p img {
  height: fit-content !important;
  width: fit-content !important;
}
