.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full viewport height */
  text-align: center;
}

.bar-graph-loader {
  display: flex;
  justify-content: space-around;
  width: 100px;
  margin-bottom: 20px; /* Space between bars and text */
  transform: rotate(180deg); /* Rotate the loader 180 degrees */
  height: 50px;
}

.bar-loader {
  width: 10px;
  height: 0;
  background: linear-gradient(135deg,#407866, #4cab86); /* Gradient bar color */
  border-radius: 5px;
  animation: grow 1.2s infinite;
  transform-origin: top;
}

@keyframes grow {
  0%, 100% {
    height: 0;
  }
  50% {
    height: 50px;
  }
}

.loader-text {
  font-size: 2em;
  color: #4cab86; /* Attractive text color */
  font-weight: bold;
}
