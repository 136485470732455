.next-prev-cust .view-ans-btn {
  height: 40px 0 !important;
}

.isActiveQuestion {
  color: #333;
  background: #008753 !important;
}

.quet-lister a.active,
.quet-lister a:hover,
.quet-lister a:focus {
  background: #008753 !important;
  color: #333;
}

.rangeslider-vertical {
  margin: 26px 165px !important;
  /* height: 250px !important; */
  height: 200px !important;
  background-image: linear-gradient(green, yellow, red);
}

.rangeslider-vertical .rangeslider__labels {
  /* width: 515px !important; */
  width: 190pt !important;
  margin: 0 0 0 30px !important;
}

/* background-color: red;  For browsers that do not support gradients*/

/* #grad1 {
  height: 200px;
  background-image: linear-gradient(green, yellow, red);
} */

/* .rangeslider-vertical .rangeslider__fill {
  height: 200px;
  background-image: linear-gradient(green, yellow, red);
} */
/* background-color: transparent; */

.rangeslider-vertical .rangeslider__fill {
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__handle {
  background: #0081f5;
}

.rangeslider__labels .rangeslider__label-item {
  font-size: 13px;
}

.review-grade-btn {
  height: 34px !important;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: purple;
  color: #fff !important;
}

.review-grade-btn:hover {
  height: 34px !important;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: rgb(245, 34, 245);
  color: #fff !important;
}

.left-navigations ul li {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  cursor: pointer !important;
}

/***
* Circular
*/
.progressbar {
  margin: 15px 50px;
}

.progressbar-marks {
  margin: 15px 150px;
}

/* .result-boxers h1 {
  padding: 0px 72px 0 50px !important;
} */

.advice-description{
  font-size: 1.3em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  border-right: 5px solid;
  animation: typing 4s, cursor .4s step-end infinite alternate;
}

@keyframes typing {
  from {width: 0;}
}

@keyframes cursor {
  50% {border-color: transparent;}
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
    visibility: visible;
  }
}

.akash p {
  font-size: 19px;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  animation-name: typing;
  animation-duration: 1s;
  animation-timing-function: steps(50, end);
  animation-iteration-count: 1;
}
.akash p:nth-child(1) {
  animation-delay: 0s;
}
.akash p:nth-child(2) {
  visibility: hidden;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}
.akash p:nth-child(3) {
  visibility: hidden;
  animation-delay: 1.6s;
  animation-fill-mode: forwards;
}
.akash p:nth-child(4) {
  visibility: hidden;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
}
.akash p:nth-child(4) {
  visibility: hidden;
  animation-delay: 2.2s;
  animation-fill-mode: forwards;
}

.result-boxers {
  width: 100%;
  overflow: hidden;
}

h1 span {
  color: #008753;
  font-weight: 500;
}

.box-head {
  height: 70px;
}

.c100 > span {
  position: absolute;
  font-weight: 600;
  width: 100%;
  line-height: normal;
  z-index: 1;
  font-size: 20px;
  color: #3c4761;
  display: block;
  text-align: center;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  align-self: anchor-center;
  white-space: normal;
}

.c100:hover > span {
  width: 100%;
  line-height: normal;
  font-size: 20px;
  color: #3c4761;
}