.logo-mini {
    font-size: 18px;
    padding-right: 10px;
    font-weight: bold;
    text-decoration: none;
}
.admin-logo, .admin-logo:hover {
    float: left;
    /* text-align: center; */
    width: 230px;
    background: #fff;
    padding: 12px 10px;
    /* border-right: 1px solid #008753; */
    border-bottom: 1px solid #008753;
    text-decoration: none;
    font-size: 16px;
  }