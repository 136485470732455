/* Styles for the tabs container */
.tabs-container {
    display: flex;
    justify-content: space-around;
    background-color: #f0f0f0;
    padding: 10px;
  }
  
  /* Styles for the individual tabs */
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 16px;
    outline: none;
  }
  
  /* Styles for the active tab */
  .tab.active {
    background-color: #3498db;
    color: #fff;
  }
  
  /* Styles for the content container */
  .content-container {
    margin-top: 20px;
  }
  