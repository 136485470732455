#img-preview {
  width: 200px;
  padding: 1px;
  border: 1px dotted #cdb;
}

.form-check-input {
  margin: 4px 10px 0 !important;
}

.table > tbody > tr > td {
  font-size: 15px;
  color: #525252;
  /* border: 1px solid #e6e6e6 !important; */
  padding: 5px 15px;
}

table img {
  width: 80px !important;
  height: 53px !important;
}
