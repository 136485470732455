@charset "utf-8";
.bounce {
  position: absolute;
  bottom: 30px;
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1200px) {
  .w-80-cust {
    width: 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-nav {
    padding: 10px 30px;
  }
  .main-nav ul li a {
    letter-spacing: 0px;
  }
  .top-blog h1 {
    font-size: 14px;
  }
  .top-blog h4 {
    font-size: 11px;
  }
  .mar-left-nav {
    margin-left: 10px;
  }
}
@media screen and (max-width: 991px) {
  .custom-nav-dash {
    padding-right: 60px !important;
  }
   .dash-logo {
    width: 210px;
    width: 180px;
    padding: 2px 0 0 0;
    border: none;
    left: 10px;
  }
  .dash-logo img {
    width: 120px;
  }
  .left-user-detail {
    display: none;
    padding: 5px 0;
    margin-left: 10px;
  } 
  .custom-radios-plan .option {
    width: 45%;
  }
  .right-nav ul li::before {
    display: none;
  }
  /* .logo {
    width: auto;
  }
  .logo img {
    width: 180px;
  } */
  .left-user-detail {
    padding: 5px 0;
    margin-left: 10px;
  }
  .right-nav ul li a {
    padding: 16px 5px;
  }
  .auth-dash-sec .left-navigations {
    left: -280px;
    z-index: 10;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .auth-dash-sec .right-auth-landing {
    padding: 20px;
  }
  .nav-togg {
    height: 40px;
    border-radius: 4px;
    width: 40px;
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 10px;
    background: #008753;
    z-index: 999;
    display: block;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .nav-togg span {
    position: absolute;
    width: 20px;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
    height: 2px;
    border-radius: 2px;
    display: block;
    background: #fff;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .nav-togg span:after,
  .nav-togg span:before {
    position: absolute;
    content: "";
    left: 0px;
    background: #fff;
    height: 2px;
    border-radius: 2px;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .nav-togg span:before {
    top: -8px;
    width: 20px;
  }
  .menu-open .nav-togg span:before {
    top: 0;
    width: 20px;
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .nav-togg span:after {
    bottom: -8px;
    width: 20px;
  }
  .menu-open .nav-togg span {
    width: 0px;
  }
  .menu-open .nav-togg span:after {
    bottom: 0;
    width: 20px;
    transform: rotate(-225deg);
    -o-transform: rotate(-225deg);
    -moz-transform: rotate(-225deg);
    -webkit-transform: rotate(-225deg);
  }
  .menu-open .auth-dash-sec .left-navigations {
    left: 0px;
  }
  .left-user-detail {
    display: none;
  }
  .log-logo {
		background: #fff;
		padding: 10px;
		border-radius: 10px;
		display: block;
		text-align: center;
		border-radius: 10px 10px 0 0;
		border-bottom: 5px solid #008753;
	}
	.log-logo img {
		width: 120px;
	}
  
	.right-regi-step h1 {
		text-align: center;
		border-bottom: 1px solid #000;
		padding-bottom: 10px;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .custom-nav {
    padding: 10px 30px;
  }
  .logo img {
    width: 180px;
  }
  .main-nav ul li a {
    padding: 5px 10px;
  }
  .mar-left-nav {
    margin-left: 0px;
  }
  .cust-tab .nav-tabs > li > a h4 {
    font-size: 14px;
  }
  .cust-tab .tab-content > .tab-pane .media-body h4 {
    font-size: 20px;
  }
  .top-blog h1 {
    font-size: 14px;
  }
  .top-blog {
    padding: 10px;
  }
  footer h3 {
    font-size: 20px;
  }
  .right-regi-step {
    width: 100%;
    height: auto;
  }
  .left-regi {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .logo {
    width: auto;
    padding: 0px;
    border: none;
  }
}
@media screen and (max-width: 767px) {
  
	.inner-que .log-btn-main .pull-left, .inner-que .log-btn-main .pull-right {
		width: 100%;
		float: none;
		text-align: center;
	}
	.inner-que .log-btn-main .btn {
		display: block;
		width: 100%;
		height: 30px;
		border-radius: 4px;
		line-height: 30px;
		margin-bottom: 10px;
	}
	.inner-que .log-btn-main .btn {
		display: block;
		width: 100%;
		height: 30px;
		border-radius: 4px;
		line-height: 30px;
		margin-bottom: 10px;
	}
  .w-80-cust {
    width: 100%;
  }
  .custom-nav {
    /* padding: 10px 30px; */
	padding: 25px 30px;
    border-bottom: 1px solid #ddd;
  }
  /* .logo img {
    width: 280px;
  } */

  .custom-radios-plan .option {
    width: 45% !important;
  }
  .main-nav ul li a {
    padding: 5px 10px;
  }
  .mar-left-nav {
    margin-left: 0px;
  }
  .cust-tab .nav-tabs > li > a h4 {
    font-size: 14px;
  }
  .cust-tab .tab-content > .tab-pane .media-body h4 {
    font-size: 20px;
  }
  .top-blog h1 {
    font-size: 14px;
  }
  .top-blog {
    padding: 10px;
  }
  footer h3 {
    font-size: 20px;
  }
  .nav-togg {
    height: 30px;
    border-radius: 4px;
    width: 30px;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 7px;
    background: #008753;
    z-index: 999;
    display: block;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .nav-togg span {
    position: absolute;
    width: 20px;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
    height: 2px;
    border-radius: 2px;
    display: block;
    background: #fff;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .nav-togg span:after,
  .nav-togg span:before {
    position: absolute;
    content: "";
    left: 0px;
    background: #fff;
    height: 2px;
    border-radius: 2px;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .nav-togg span:before {
    top: -8px;
    width: 20px;
  }
  .menu-open .nav-togg span:before {
    top: 0;
    width: 20px;
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .nav-togg span:after {
    bottom: -8px;
    width: 20px;
  }
  .menu-open .nav-togg span {
    width: 0px;
  }
  .menu-open .nav-togg span:after {
    bottom: 0;
    width: 20px;
    transform: rotate(-225deg);
    -o-transform: rotate(-225deg);
    -moz-transform: rotate(-225deg);
    -webkit-transform: rotate(-225deg);
  }
  .main-nav {
    position: absolute;
    width: 100%;
    top: 100px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    -moz-transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
  }
  .menu-open .main-nav {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }
  .main-nav ul li {
    display: block;
  }
  .main-nav ul li a {
    padding: 15px 10px;
    text-align: center;
  }
  .main-nav ul li a:hover,
  .main-nav ul li.active a {
    padding: 15px 10px;
    border: none;
  }
  .main-nav ul li.log-reg a {
    padding: 15px 10px;
    border-radius: 0px;
    background: #fff;
    color: #000;
  }
  .main-nav ul li.log-reg a:hover {
    color: #000;
  }
  .why-choos-list ul li {
    width: 50%;
    text-align: center;
    border: 1px solid #ddd !important;
  }
  .exam-category-sec .owl-controls .owl-nav .owl-prev {
    left: 0px;
  }
  .exam-category-sec .owl-controls .owl-nav .owl-next {
    right: 0px;
  }
  .cust-tab .nav-tabs {
    overflow: auto;
    white-space: nowrap;
  }
  .cust-tab .nav-tabs > li {
    width: 200px;
    height: auto;
    float: none;
    display: inline-block;
    margin: 0px;
  }
  .footer-logo {
    margin: 0px;
  }
  .top-footer {
    text-align: center;
  }
  footer h3 {
    margin: 20px 0 10px;
  }
  .footer-logo img {
    width: 100px;
    margin-bottom: 20px;
  }
  .news-sec .col-sm-4 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  .why-choose-sec {
    padding: 4% 0 0px;
  }
  .right-regi-step {
    width: 100%;
    height: auto;
    padding: 0 10%;
  }
  .left-regi {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .right-regi-step h1,
  .left-regi h1 {
    font-size: 24px;
  }
  .logo {
    width: auto;
    padding: 0px;
    border: none;
  }
  .page-banner-sec h1 {
    font-size: 30px !important;
  }
  .end-plan h1 {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 540px) {
  .dash-logo {
    position: relative;
    display: inline-block;
    width: auto !important;
  }
  .right-nav ul li a {
    padding: 15px 5px;
    font-size: 12px;
  }
  .custom-nav-dash ul {
    margin-bottom: 0px;
  }
  .right-nav {
    text-align: center;
    display: inline-block;
  }
  .custom-nav-dash {
    padding-right: 50px !important;
  }
  .dash-logo img {
    width: 100px;
  }
}
@media screen and (max-width: 630px) {
  .custom-radios-plan .option {
    width: 100% !important;
    margin: 0px 0 10px 0 !important;
  }
  .tab {
    font-size: 14px !important;
  }
  .tabs-container {
    display: inline-block !important;
  }
  .exam-logos h4 {
    font-size: 14px;
  }
  .cms-content-sec p,
  .news-page-sec p {
    line-height: normal;
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .why-choos-list ul li {
    width: 100%;
  }
  .section-head h1 {
    font-size: 22px;
    margin: 10px 0;
  }
  .inner-banner-content h1 {
    font-size: 22px;
  }
  .inner-banner-content p {
    line-height: 26px;
  }
  .cust-tab .tab-content > .tab-pane .media-body p,
  .why-choos-list ul li p {
    line-height: 26px;
    font-size: 14px;
  }
  .main-choose-outer {
    height: auto;
  }
  .cust-tab .tab-content {
    padding: 10px;
  }
  .cust-tab .tab-content > .tab-pane .media-body {
    padding-left: 0px;
  }
  .why-choos-list ul li h3 {
    font-size: 16px;
  }
}
@media screen and (max-width: 360px) {
  .custom-nav {
    padding: 10px;
  }
  .logo img {
    width: 220px;
  }
  .main-nav ul li a:hover,
  .main-nav ul li.active a,
  .main-nav ul li a {
    padding: 10px !important;
  }
}