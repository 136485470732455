.modal-dialog {
  top: 35px;
}

.modal-backdrop {
  position: relative !important;
}

.modal-backdrop.in {
  opacity: 0 !important;
}

/* For summernote override unordered and order list */

.note-editable ol li {
  list-style: decimal !important;
  list-style-position: inside !important;
}
.note-editable ul li {
  list-style: disc !important;
  list-style-position: inside !important;
}

/* .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
} */
