@font-face {
  font-family: 'ots';
  src:  url('../fonts/ots.eot?6rds1a');
  src:  url('../fonts/ots.eot?6rds1a#iefix') format('embedded-opentype'),
    url('../fonts/ots.ttf?6rds1a') format('truetype'),
    url('../fonts/ots.woff?6rds1a') format('woff'),
    url('../fonts/ots.svg?6rds1a#ots') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ots-"], [class*=" ots-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ots' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ots-expand:before {
  content: "\e900";
}
.ots-play-button-on-monitor-screen:before {
  content: "\e901";
}
.ots-test:before {
  content: "\e902";
}
.ots-exam:before {
  content: "\e903";
}
.ots-open-book:before {
  content: "\e904";
}
.ots-technical-support:before {
  content: "\e905";
}
.ots-headphones:before {
  content: "\e906";
}
.ots-network:before {
  content: "\e907";
}
.ots-global-network:before {
  content: "\e908";
}
.ots-translation:before {
  content: "\e909";
}
.ots-skill:before {
  content: "\e90a";
}
.ots-book:before {
  content: "\e90b";
}
.ots-test-1:before {
  content: "\e90c";
}
.ots-key:before {
  content: "\e90d";
}
.ots-passkey:before {
  content: "\e90e";
}
.ots-camera:before {
  content: "\e90f";
}
.ots-analysis:before {
  content: "\e910";
}
.ots-analytics:before {
  content: "\e911";
}
.ots-user:before {
  content: "\e912";
}
.ots-enter:before {
  content: "\e913";
}
.ots-logout:before {
  content: "\e914";
}
.ots-right:before {
  content: "\e915";
}
.ots-right-arrow-angle:before {
  content: "\e916";
}
