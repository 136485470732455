.profiles-large {
    float: left;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 4px 5px 15px #0003;
    margin-top: 20px;
}

.profiles-large .left-details {
    float: left;
    width: 50%;
    padding: 10px;
}

.profiles-large .right-details {
    float: left;
    width: 50%;
    padding: 10px;
}

.title-profiles {
    float: left;
    width: 40%;
    padding: 20px 30px;
}

.title-profiles ul {
    float: left;
    width: 100%;
    padding: 0px;
}

.title-profiles ul li {
    list-style-type: none;
    font-size: 20px;
    line-height: 36px;
    color: #00a65a;
    font-family: 'RalewaySemiBold';
    line-height: 50px;
}

.colns {
    float: left;
    width: 10%;
    padding: 20px 30px;
}

.colns ul {
    padding: 0px;
    margin: 0px;
}

.colns ul li {
    list-style-type: none;
    font-size: 20px;
    line-height: 36px;
    color: #00a65a;
    font-family: 'RalewaySemiBold';
    line-height: 50px;
}

.originals-profiles {
    float: left;
    width: 40%;
    padding: 20px 30px;
}

.originals-profiles ul {
    padding: 0px;
    margin: 0px;
}
.originals-profiles ul li {
    list-style-type: none;
    font-size: 20px;
    line-height: 36px;
    color: #000;
    font-family: 'RalewaySemiBold';
    line-height: 50px;
}

.right-prof-imgs {
    width: 220px;
    height: 220px;
    float: right;
}

.right-prof-imgs img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 7px 6px 10px -2px #a9a6a6;
    border-radius: 4px;
}

.page-banner-sec {
    padding: 104px 0 35px !important;
    position: relative;
    color: #fff;
    z-index: 1;
}

.subscription-page {
    padding: 3% 0 !important;
}

.end-plan {
    padding: 25px !important;
    margin-bottom: 20px !important;
}
.end-plan h1 {
    margin: 0 0 0px !important;
}

.end-plan.instruction {
    text-align: start !important;
}
.instruction ul li {
    list-style-type: unset !important;
}

.start-mock-sec {
    padding: 104px 0 35px !important;
    position: relative;
    color: #000;
    z-index: 1;
}

.start-mock-btn {
    border-radius: 4px;
    background-color: #008753;
    border: none;
    color: #FFFFFF;
    font-size: 28px !important;
    padding: 20px !important;
    width: 300px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
}

.start-mock-btn.btn:hover, .start-mock-btn.btn:active, .start-mock-btn.btn:focus {
    opacity: 1;
    right: 0;
    color: #fff;
    background: #00a65a;
}

.user-exam {
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
}

.cust-redio label small, .small {
    font-size: 115%;
    margin-top: 10px;
    font-weight: bold;
}

/* Disable text selection for the entire page */
body {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* Optionally, you can disable text selection for specific elements */
.no-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}