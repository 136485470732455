/* .controlRow__root.row .input-group {
  
} */
.controlRow__root.row .input-group > .input-group-append {
  display: flex !important;
}

.controlRow__root, .table-datatable__root {
  margin-right: 5px !important;
  margin-left: 15px !important;
}
